.custom-toaster {
  z-index: 1054;
  position: fixed;
  top: 0;
  right: 0;
  margin: 0.5rem;
  background-color: red;
  width: 20rem;
  padding: 1rem;


}