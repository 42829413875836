.cat-shop-wrapper {
  margin: 2rem 0 6rem;
  display: flex;
  flex-flow: row wrap;


  @media screen and (max-width: 425px) {
    margin: auto;
  }


  .cat-prod-sec-left-wrap {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    font-family: 'Sen', sans-serif;
    margin: 3rem auto;
    width: 75%;

    .head {
      margin: 0px 0px 2rem;
      border: 1px solid lightgray;
      padding: 0.5rem;
    }

  }

  .featured-cat-wrap {
    max-height: 23rem;
    overflow-y: auto;
    border: 1px solid lightgrey;
    padding: 0 1rem;

    .featured-cat-ul {
      list-style: none;
      font-size: larger;
      padding: 0px;

      .featured-cat-li {
        margin: 1rem auto;
        cursor: pointer;
        color: inherit;
        font-weight: inherit;
        padding: 0.2rem 1rem;
      }

      .featured-cat-li:hover {
        background-color: #d3d3d357;
      }
    }
  }
}