.payment-method-option{
  border-bottom: 1px solid lightgray;

  .payment-method-option-btn-wrap{
    border-radius: 8px;
    background-color: #f2f2f2;
    padding: 1em;
    height: auto;
    width: 90%;
    margin: 1em auto;
    cursor: pointer;

    .payment-method-option-btn {
      width: 2em;
      height: 2em;
      text-align: center;
      padding: 0.5em;
      margin: 0.2em 0.8em 0 0;
    }

    .payment-method-option-title{
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #333333;
      font-family: Sen,sans-serif;
    }

    .payment-method-option-available-balance{
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #333333;
      font-family: Sen,sans-serif;
      margin: 0.5em 0 0 0;
    }




  }
  .payment-method-auth-wrap{
    width: 75%;
    margin: auto;

    .payment-method-auth-pin-title-des-wrap{
      padding: 0 0.8em;
      .payment-method-auth-pin-title{
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #333333;
        margin: 0.4em 0;
      }

      .payment-method-auth-pin-des{
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #333333;
        margin: 0.4em 0;
      }
    }



    .payment-method-auth-pin-input-wrap{
      //display: flex;
      //flex-flow: row wrap;
      //width: 100%;
      .payment-method-auth-pin-input{
        width: 48px;
        height: 48px;
        margin: 16px 8px;
        border-radius: 6px;
        background-color: #f2f2f2;
        //flex: 1;
      }
    }

    .payment-method-auth-verify-btn-wrap{
      padding: 1em;
      margin: 0 1em;
      cursor: pointer;
      .payment-method-auth-verify-btn{
        padding: 16px 86px 15px 88px;
        border-radius: 6px;
        background-color: #039eff;
        font-family: Sen;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
      }
    }


  }


}
