.links-column{
    flex: 1 1 0%;
    margin: 0 0.8em;
}
.links-column-title{
    width: 100%;
    height: 17px;
    margin: 0px 86px 27px 0px;
    font-family: Sen, sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    /*color: rgb(26, 26, 26);*/
    color: whitesmoke;
}
.links-column-list{
    list-style: none;
    padding: 0px;
}
.footer-links {
    font-family: Sen, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    /*color: rgb(51, 51, 51);*/
    color: whitesmoke;

}