.product-card-wrap{
  display: flex;
  flex-flow: column wrap;
  width: 12em;
  height: auto;
  /*margin: 0.1em;*/
  margin: auto;
  padding: 10px 8px 24px;
  cursor: pointer;
  border-radius: 0px;
  box-shadow: 0px 0px;
  border: 1px solid rgba(255, 255, 255, 0);
}

.product-card-wrap i {
  font-size: 3rem;
  color: darkgray;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-flow: column;
}

.product-card-img {
  width: 10em;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: center;
  height: 10em;
  margin: 0 0.5em 1em;
  border-radius: 8px;
  background-color: rgb(240, 240, 240);
}

.product-card-img-title{
  width: auto;
  height: 37px;
  font-family: Sen, sans-serif;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  margin: 0px 10px;
  color: rgb(26, 26, 26);
}

.product-card-price-wrap{
  display: flex;
  margin: 8px 10px;
}

.product-card-price{
  flex: 1 1 0%;
  width: 104px;
  height: 22px;
  font-family: Sen, sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgb(26, 26, 26);
}

.product-card-discount{
  height: 19px;
  font-family: Sen, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgb(26, 26, 26);
}

.product-card-original-price{
  width: 73px;
  height: 14px;
  margin: 2px 83px 0px 10px;
  font-family: Sen, sans-serif;
  text-decoration: line-through;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgb(153, 153, 153);
}