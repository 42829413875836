.filter-left-sec-wrap{
  width: 100%;
  height: auto;
  /*padding: 2em 1em 4em;*/
  padding: 2em 0 4em;
  border-radius: 8px;
  background-color: #ffffff;
}

.filter-price-range-bar{
  margin: 2rem;
}

.filter-product-categories-wrap{
  padding: 0 1em;
  margin: 0 0 2em 0;
}

.filter-product-categories-head{
  display: flex;
  flex-flow: row wrap;
  cursor: pointer;
}
.filter-product-categories-head-title{
  flex: 1;
  font-family: Sen;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  padding: 0.2em 0 0 0;
}

.filter-product-categories-body{
  margin: 0.5em 0;
  max-height: 0;
  overflow: hidden;
  text-transform: inherit;
  transition-duration: 0.5s;
  transition-property: max-height;
  z-index: 1;
  position: relative;
}
.filter-product-categories-body-opened .filter-product-categories-ul{
  max-height: 100rem;
  /*transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);*/
  /*transition-duration: 0.5s;*/
  /*transition-property: max-height;*/
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.2s;
  transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  transition-property: opacity, transform;
}
.filter-product-categories-ul{
  list-style: none;
  padding: 0 0 0 1em;
}
.filter-product-categories-li{
  font-family: Sen;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #666666;
  margin: 1em 0;
}

.filter-price-range-wrap{
  padding: 0 1em;
  margin: 0 0 2em 0;
}

.filter-price-range-title{
  font-family: Sen;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
}
.filter-price-range-end-points{
  display: flex;
  flex-flow: row wrap;
}
.filter-price-range-left,.filter-price-range-right{
  border-radius: 6px;
  background-color: #f2f2f2;
  font-family: Sen;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #999999;
  height: auto;
  width: 100%;
  padding: 0.4em;
}
.filter-price-range-center{
  font-family: Sen;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  text-align: center;
}

.filter-wrap{
  padding: 0 1em;
  margin: 0 0 2em 0;
}

.filter-head{
  display: flex;
  flex-flow: row wrap;
  cursor: pointer;
}
.filter-head-title{
  flex: 1 1;
  font-family: Sen;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  padding: 0.2em 0 0 0;
}
.filter-head-icon{

}

.filter-search-body-opened{
  max-height: 100rem;
  /*transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);*/
  /*transition-duration: 0.5s;*/
  /*transition-property: max-height;*/
  opacity: 1;
  transform: translateY(0);
  /*transition-delay: 0.2s;*/
  /*transition-timing-function: ease-in-out;*/
  /*transition-duration: 0.2s;*/
  transition-property: opacity, transform;
}

.filter-search-body{
  margin: 0.5em 0;
  max-height: 0;
  overflow: hidden;
  text-transform: inherit;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
  transition-property: max-height;
  z-index: 1;
  position: relative;
}

.filter-search-bar{
  margin: 2em 0;
  font-family: Sen;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.filter-search-list{
  margin-top: 2em;
}

.filter-search-list-clear-all{
  font-family: Sen;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #039eff;
  width: fit-content;
  cursor: pointer;
}

.filter-search-list-ul{
  list-style: none;
  padding: 0;
  margin: 1em 0;
}

.filter-search-list-li{
  margin: 1em 0;
}
.filter-search-list-item{
  display: flex;
  flex-flow: row wrap;
  font-family: Sen;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #666666;
  padding: 0 0 0 0;
}

.filter-search-list-li-count {
  padding-top: 0.4em;
}

.range-slider-container {
  margin: 1em auto;
}

