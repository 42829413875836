.ads-sec-left {


  @media screen and (min-width: 1024px) {
    width: 70%;
  }

  @media screen and (max-width: 1023px) {
    width: 100%;
  }

}

.ads-sec-right {
  @media screen and (min-width: 1024px) {
    width: 28%;
    margin: 0 0 0 1em;
  }

  @media screen and (max-width: 1023px) {
    width: 100%;
    margin: 1em 0 0 0;
  }
}

.right-sec-ads-img {


  @media screen and (min-width: 1025px) {
    margin: 0px 0px 1em;
    height: 16.8em;
    width: 100%;
    background-size: cover;
  }

  @media screen and (max-width: 1024px) {
    margin: 0px 0px 1em;
    height: 11.7em;
    width: 100%;
    background-size: cover;
  }

  //background-size: contain;
  //background-repeat: no-repeat;
  //background-position: center;
}