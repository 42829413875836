
.logo {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 9rem;
  height: 3rem;

  @media screen and (max-width: 1024px) and (min-width: 769px) {
    width: 8rem;
    height: 2.5rem;
  }
}

.logo-wrapper {
  height: auto;
  margin: 1rem;
}

.profile-btn {
  display: flex;
  flex-flow: row wrap;
  background-color: #5db7fe;
  border-radius: 1em;
  width: 6rem;
  height: auto;
  padding: 0.8em;
  color: white;
  margin: 1rem auto;
  font-size: small;
  cursor: pointer;
  font-family: inherit;
  font-weight: 600;
  justify-content: center;
 

  @media screen and (max-width: 1024px) and (min-width: 769px) {
    width: 5rem;
    padding: 0.5rem;
    font-size: x-small;
  }
}
.profile-btn-link:hover{
  text-decoration: none;
}
.navbar-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  box-shadow: 1px 1px 6px 1px lightgrey;

  @media screen and (min-width: 769px) {
    display: flex;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.small-navbar-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: auto;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  box-shadow: 1px 1px 6px 1px lightgrey;
  @media screen and (min-width: 769px) {
    display: none;
  }
}

.navbar-hamburger-btn {
  @media screen and (min-width: 769px) {
    display: none;
  }
}

.nav-links-wrapper {
  flex: 2 1 0%;
  padding: 0.2em 0px 0px 0;
  height: auto;
  margin: 1.4rem 0;
}

.nav-links {
  flex-flow: wrap;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-links-btn {
  font-weight: bold;
  color: rgb(51, 51, 51);
  height: 1em;
  margin: 0px 1em 0px 0px;
  font-family: Sen, sans-serif;
  /*font-size: 1rem;*/
  font-size: 1.1rem;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  text-decoration: none;

  @media screen and (max-width: 1024px) and (min-width: 769px) {
    font-size: 1rem !important;
  }
}

.navbar-hamburger-btn {
  @media screen and (max-width: 768px) {
    margin: 1.3em 1em 0 auto;
    text-align: right;
    width: 6%;
    height: 6%;
  }
}

.sticky-navbar {
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 1054;
  background-color: white;
  box-shadow: 1px 1px 6px 1px lightgrey;
}

.sticky-navbar .logo-wrapper {
  margin: 0.8rem;
}

.sticky-navbar .nav-links-wrapper {
  margin: 0.8rem 0 !important;
}

.sticky-navbar .nav-links-btn {
  font-size: 1rem !important;
}

.sticky-navbar .profile-btn {
  margin: 0.5rem auto;
}

.sticky-navbar .logo {
  width: 6rem;
  height: 2rem;
}

.sticky-navbar .category-btn-wrapper {
  margin: 0.2rem 0.5rem 0.5rem 0;
}

.cart-wrap {
  margin: auto;
  cursor: pointer;
}
.cart-wrap .badge {
  color: white;
  background-color: red;
  height: 1.5rem;
  min-width: 1.5rem;
  border-radius: 1rem;
  padding: 0.4rem;
  position: relative;
  right: 3rem;
  bottom: 0.4rem;
}

.search-bar-wrapper {
  flex: 3 1 0%;
  margin: 1em 1em 1em 0;
  min-width: 12em;
}

/* NAVIGATION */

div.stroke ul {
  list-style: none;
  text-align: center;
}
div.stroke ul li {
  /*display: inline-block;*/
}
div.stroke ul li .nav-links-btn {
  /*display: block;*/
  /*padding: 15px;*/
  text-decoration: none;
  /*color: #aaa;*/
  /*font-weight: 800;*/
  /*text-transform: uppercase;*/
  /*margin: 0 10px;*/
}
div.stroke ul li .nav-links-btn,
div.stroke ul li .nav-links-btn:after,
div.stroke ul li .nav-links-btn:before {
  transition: all 0.5s;
}
div.stroke ul li .nav-links-btn:hover {
  /*color: #555;*/
  color: #5db7fe;
  /*background-color: rgba(122, 196, 245, 0.64);*/
}

/* stroke */
div.stroke ul li .nav-links-btn,
div.fill ul li .nav-links-btn {
  position: relative;
}
div.stroke ul li .nav-links-btn:after,
div.fill ul li .nav-links-btn:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: ".";
  color: transparent;
  /*background: #aaa;*/
  background: #5db7fe;
  height: 2px;
}
div.stroke ul li a:hover:after {
  width: 100%;
}
div.fill ul li .nav-links-btn {
  transition: all 2s;
}
div.fill ul li .nav-links-btn:after {
  text-align: left;
  content: ".";
  margin: 0;
  opacity: 0;
}
div.fill ul li .nav-links-btn:hover {
  color: #fff;
  z-index: 1;
}
div.fill ul li .nav-links-btn:hover:after {
  z-index: -10;
  animation: fill 1s forwards;
  -webkit-animation: fill 1s forwards;
  -moz-animation: fill 1s forwards;
  opacity: 1;
}

/* Circle */
div.circle ul li .nav-links-btn {
  position: relative;
  overflow: hidden;
  z-index: 1;
}
div.circle ul li .nav-links-btn:after {
  display: block;
  position: absolute;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: ".";
  color: transparent;
  width: 1px;
  height: 1px;
  border-radius: 50%;
  background: transparent;
}
div.circle ul li .nav-links-btn:hover:after {
  -webkit-animation: circle 1.5s ease-in forwards;
}
/* SHIFT */
div.shift ul li .nav-links-btn {
  position: relative;
  z-index: 1;
}
div.shift ul li .nav-links-btn:hover {
  color: #91640f;
}
div.shift ul li .nav-links-btn:after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 1px;
  content: ".";
  color: transparent;
  background: #f1c40f;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
}
div.shift ul li .nav-links-btn:hover:after {
  opacity: 1;
  visibility: visible;
  height: 100%;
}

/* Keyframes */
@-webkit-keyframes fill {
  0% {
    width: 0%;
    height: 1px;
  }
  50% {
    width: 100%;
    height: 1px;
  }
  100% {
    width: 100%;
    height: 100%;
    background: #333;
  }
}

/* Keyframes */
@-webkit-keyframes circle {
  0% {
    width: 1px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 1px;
    z-index: -1;
    background: #eee;
    border-radius: 100%;
  }
  100% {
    background: #aaa;
    height: 5000%;
    width: 5000%;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 0;
  }
}
