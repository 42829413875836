.delivery-detail-wrap{
  margin: 1.5em 0;
  background-color: white;
  border-radius: 6px;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .delivery-detail-head {
    border-bottom: 2px solid #ececec;
    padding: 0.7em 1em;
    height: auto;
    width: 100%;

    .delivery-detail-head-icon {
      width: 3em;
      height: 3em;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin: 0.2em 0.5em 0em 0.5em;
    }

    .delivery-detail-head-title{
      font-family: Sen;
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #1a1a1a;
      padding: 0.5em 0;
    }

  }

  .delivery-detail-detail{
    margin: 1.5em;

    .delivery-detail-input-label{
      font-family: Sen,sans-serif;
      font-size: 0.8em;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #333333;
    }

    .delivery-detail-input{
      font-family: Sen,sans-serif;
      font-size: 0.9em;
      //font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      width: 100%;
      height: 3em;
      margin: auto;
      border-radius: 6px;
      background-color: #f2f2f2;
    }

    .delivery-detail-confirm-btn-wrap{
      padding: 1em 0;
      cursor: pointer;

      .delivery-detail-confirm-btn{
        padding: 1em;
        border-radius: 6px;
        background-color: #039eff;
        font-family: Sen,sans-serif;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
      }
    }
  }

}
