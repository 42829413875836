.slider-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1.5em 0;
}

.slider-container {
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  scroll-behavior: smooth;
  /*margin: 0 1em;*/
  margin: 0 auto;
}

.btn-prev {
  left: 0rem;
  position: absolute;
  cursor: pointer;
  background: rgb(255, 255, 255);
  text-align: center;
  color: white;
  /*height: 74px;*/
  width: 3rem;
  height: 3rem;
  margin: 0px 0px 87px;
  /*padding: 25px;*/
  padding: 12px;
  /*border-radius: 0px 35px 35px 0px;*/
  border-radius: 2rem;
  /*box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 100px 0px;*/
  box-shadow: 0px 0px 20px 0px #b9b8b8;
}

.btn-next {
  right: 0rem;
  position: absolute;
  cursor: pointer;
  background: rgb(255, 255, 255);
  text-align: center;
  color: white;
  width: 3rem;
  height: 3rem;
  /*height: 74px;*/
  margin: 0px 0px 87px;
  /*padding: 25px;*/
  padding: 12px;
  /*border-radius: 35px 0px 0px 35px;*/
  border-radius: 2rem;

  /*box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 100px 0px;*/
  box-shadow: 0px 0px 20px 0px #b9b8b8;
}


.slider-next-btn-wrapper{
  position: relative;
  bottom: 20em;
  left: 76.3em;
  width: auto;
}
.slider-prev-btn-wrapper{
  position: relative;
  bottom: 20em;
  right: 76.3em;
  width: auto;
}