.blog-page-container {
  width: 100%;
  padding: 0 2em;


  .blog-page-tabs-wrap {
    margin: 1em;

    .blog-page-tabs-ul {
      list-style: none;
      display: flex;
      flex-flow: row wrap;
      padding: 0;
      margin: 0 auto;
      height: auto;
      width: fit-content;


      .blog-page-tabs-li {
        font-weight: bold;
        color: #333333;
        font-family: Sen, sans-serif;
        font-size: larger;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        text-decoration: none;
        padding: 0.5em 1em;
        cursor: pointer;
        border-bottom: 2px solid white;

      }

      .blog-page-tabs-li:hover {
        border-bottom: 2px solid #039eff;
      }

      .active-tab {
        color: #039eff;
      }


    }
  }


}

.card {
  .blog-img {
    //background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 24em;
    height: 14em;
  }

  .card-body {
    font-family: 'Sen', sans-serif;
  }
}
