

.client-support-bar-wrapper{
  display: flex;
  margin: 2px 0px 2px auto;
  list-style-type: none;
  float: right;
}

.client-support-btn-wrap{
  margin: 0px 12px;
}

.client-support-btn{
  height: 14px;
  margin: 0px 16px 0px 0px;
  font-family: Sen, sans-serif;
  font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    text-decoration: none;
    color: rgb(3, 158, 255);
}

.client-support-bar-left-section{
    display: flex;
    /*height: 3em;*/
    height: auto;
    margin: 0.5rem;
    flex-flow: row;
}

@media screen and (max-width: 575px) {
    .client-support-bar-container {
        display: none;
    }
}

@media screen and (min-width: 576px) {
    .client-support-bar-container {
        width: 100%;
        height: auto;
        /*margin: 0px 8px 0px 0px;*/
        padding: 1px 0px;
        border-radius: 4px;
        background-color: rgb(242, 242, 242);
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }
}
