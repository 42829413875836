.accordion-list {
    list-style: none;
    margin: 0;
    padding: 0;
    background-color: #fff;
    width: 100%;
    border-radius: 0 0 0.5em 0.5em;
    overflow: hidden;
    /*box-shadow: 0 0 0.8rem 0.1rem rgba(15, 72, 179, 0.06), 0 20px 30px -10px rgba(15, 72, 179, 0.2);*/
}
.accordion-list__item{
    cursor: pointer;
    margin: 0.4em;
}
/*.accordion-list__item + .accordion-list__item {*/
/*    border-top: 1px solid #ced4da;*/
/*}*/
.accordion-item__icon__wrap{
    margin: 0 1em;
    padding: 0 0 0.1em 0;
}
/*.accordion-item.accordion-item--opened .accordion-item__icon {*/
/*    transform: rotate(180deg);*/
/*}*/
.accordion-item.accordion-item--opened .accordion-item__inner {
    max-height: 100rem;
    transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
    transition-duration: 0.5s;
    transition-property: max-height;
}
.accordion-item.accordion-item--opened .accordion-item__content {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.2s;
    transition-timing-function: ease-in-out;
    transition-duration: 0.2s;
    transition-property: opacity, transform;
}

.accordion-item .accordion-item__line {
  display: block;
  padding: 0.8rem 1.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f7f7f7;
  z-index: 2;
  position: relative;
  border: 2px solid white;
}

.accordion-item .accordion-item__line.active {
  border: 2px solid #5db7fe;
}

.accordion-item .accordion-item__title {
  font-size: 1rem;
  margin: 0;
  font-family: Sen, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  text-align: left;
}
.accordion-item .accordion-item__icon {
    width: 1.2rem;
    height: 1.2rem;
    transition: transform 0.3s ease-in-out;
    background-size: contain;
    background-repeat: no-repeat;
    /*background-image: url(data:image/png;*/
    /*base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAQAAABIkb+zAAABGklEQVR4Ae3RAcZCQRiF4buDfwshBGi+2UQgcIGAVtpSIuS/KyilG+UTcbk6zIH3GQBm3mM6AAAAAAAAAACA+eqf/yZBXcV/2XeCVPYx1FXj/FjGUMd45AQp/1HHGGLZNL+e61jHnKDmv8652YT1IvPfE2LX/Sh27/ycsF60yT/lk58JYn6eU4MJccjnlAmZ/33i0OAH4jg9Qcw/5g9YJpS+m6n0xvzpCfVe+nn59S7kGyYo+YYJWz3fO+E2PaFs9XzPhMy/6fmWCXq+YUJs9HzrhLh+JsQmrnq+bYKeb52g53snXPR88wQ93z9Bz/dP0PP9E/R89wQ93zpBz7dO0POtE/R86wQ93zpBzzdP+MoHAAAAAAAAAADAExTnTW20AtjhAAAAAElFTkSuQmCC);*/

    opacity: 0.6;
}
.accordion-item .accordion-item__inner {
    max-height: 0;
    overflow: hidden;
    /*text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);*/
    /*text-transform: cubic-bezier(0.895, 0.03, 0.685, 0.22);*/
    text-transform: inherit;
    transition-duration: 0.5s;
    transition-property: max-height;
    z-index: 1;
    position: relative;
}
.accordion-item .accordion-item__content {
    opacity: 0;
    transform: translateY(-1rem);
    transition-timing-function: linear, ease;
    transition-duration: 0.1s;
    transition-property: opacity, transform;
    transition-delay: 0.5s;
    padding: 0 1.2rem 1.2rem;
}
.accordion-item__paragraph {
  margin: 0;
  font-size: 1rem;
  color: black;
  font-weight: 300;
  line-height: 1.3;
  text-align: left;
}











.accordion-item-list-group-wrap{
    margin: 2em;
}
.accordion-item-list-group-item-wrap{
    display: flex;
    flex-flow: row wrap;
    height: auto;
}
.accordion-item-list-group-item-left{
    flex: 1;
    padding: 1.5em;
    font-family: Sen;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333333;
}
.accordion-item-list-group-item-right{
    flex: 2;
    padding: 1.5em;
    font-family: Sen;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #666666;
}
.accordion-item-list-ul{
    text-decoration: none;
    list-style: none;
    padding: 0;
}

.accordion-item-list-li{
    border-bottom: 1px solid lightgray;
}
.accordion-item-list-ul li:last-child{
    border-bottom: none;
}


.accordion-wrapper {
  font-family: Sen, sans-serif;
  font-size: 1.5rem;
}
