.category-btn-wrapper{

    /*width: 6em;*/
    /*height: 1em;*/
    /*margin: 0.2em 0.5em 0.5em 0;*/
    cursor: pointer;
}
.category-btn{
    border: 1px solid rgb(206, 212, 218);
    height: 90%;
    border-radius: 3px;
    padding: 5px 0px 0px 14px;
    display: flex;
    cursor: pointer;
}
.category-btn-title {
    width: 117px;
    margin: 4px 24px 0px 0px;
    font-family: Sen, sans-serif;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    float: right;
    color: rgb(51, 51, 51);
}
.cat-btn-dropdown {
    position: relative;
    top: 6%;
    /*left: 14px;*/
    max-width: 14rem;
    z-index: 3;
    border: 1px solid rgba(0, 0, 0, 0.04);
    box-shadow: rgba(0, 0, 0, 0.14) 0px 16px 24px 2px;
    background-color: white;

    width: 14rem;
    height: 12em;
    overflow-y: scroll;
}
.cat-btn-dropdown-ul{
    list-style: none;
    padding: 1rem;
    margin: 0px;
}
.cat-btn-dropdown-li{
    margin: 0.8em;
    font-family: Sen, sans-serif;
    font-size: 13px;
    padding: 0.6em;
    height: 2.5em;
    /* font-weight: bold; */
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgb(51, 51, 51);
}

.cat-btn-dropdown-li:hover {
    /*font-weight: bold;*/
    background-color: #f2f2f2;
    cursor: pointer;
}


/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}




@media screen and (max-width: 375px) {
    .category-btn-wrapper {
        /*width: 15.5em;*/
        width: 6em;
    }
}

@media screen and (max-width: 320px) {
    .category-btn-wrapper {
        /*width: 15.5em;*/
        width: 6em;
    }
}