.cp-wrap {
  margin: 2rem auto;

  .cp-title {
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
    margin: 2rem auto;
    font-family: 'Sen', sans-serif;
  }

  .cp-paragraph {
    font-size: 2rem;
    text-align: center;
    margin: 1rem auto 3rem;
  }

  .cp-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 20rem;
    width: 100%;
  }

}