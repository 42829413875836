.left-tex-right-image-sec {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  .left-sec-wrapper {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    height: 100%;
    max-width: 80%;
    margin: auto;

    .title {
      font-size: 2rem;
      font-weight: bold;
      font-family: Sen, sans-serif;
    }

    .description {
      font-size: 2rem;
      //font-weight: bold;
      font-family: Sen, sans-serif;
    }

    .btn {
      max-width: 16rem;
      margin: 2rem 0;
    }
  }


  .right-sec-wrapper {

    .img {
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 100%;
      height: 34rem;
    }

  }
}