.result-right-sec{
  width: 100%;
  height: auto;
  padding: 0.5em 0 4em;
  border-radius: 8px;
  background-color: #ffffff;
}

.result-right-head{
  padding: 1em;
}
.result-right-head-title-wrap{
  display: flex;
  flex-flow: row wrap;
  margin: 0 0 1.2em 0;
}
.result-right-head-title{
  font-family: Sen;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1a1a1a;
}

.result-right-head-count{
  font-family: Sen;
  font-size: 1em;
  font-weight: 200;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #666666;
  padding: 0.7em;
}


.result-right-head-tag{
    width: 7em;
    display: flex;
    flex-flow: row wrap;
    border: 1px solid #666666;
    border-radius: 6px;
    margin: 0.5em;
}
.head-tag-label{
  width: 70%;
  padding: 1em;
  font-family: Sen;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #666666;
}

.head-tag-icon {
    width: 30%;
    padding: 0.6em;
}

.searched-results-pagination {
    margin: 1em 0;
}
