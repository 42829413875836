.payment-method-wrap{
  margin: 1.5em 0;
  background-color: white;
  border-radius: 6px;

  .payment-method-head{
    border-bottom: 2px solid #ececec;
    padding: 0.7em 1em;
    height: auto;
    width: 100%;

    .payment-method-head-icon{
      width: 3em;
      height: 3em;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin: 0.2em 0.5em 0em 0.5em;
    }

    .payment-method-head-title{
      font-family: Sen;
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #1a1a1a;
      padding: 0.5em 0;
    }

  }

  .payment-method-detail{
    //margin: 1.5em;
    width: 100%;
  }

}
