.bg-light {
  background-color: #fff !important;
  /* padding-top: "2%";
  padding-bottom: "2%"; */
  /* height: 60px !important; */
}
.modal-content span {
  color: red;
}
.switchClass {
  color: red;
}
.switchClass:hover {
  color: rgb(167, 81, 81);
}

@media screen and (max-width: 700px) {
  .navbarIcons {
    padding-left: 50px !important;
  }
}
