.blog-detail-rating-wrap{
  font-size: 1.5rem;

  .rating-title{
    text-align: center;
  }
  .rating-star{
    display: flex;
    flex-flow: row;
    justify-content: center;
  }
}

.related-articles-title{
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  font-family: 'Sen',sans-serif;
}

.related-blog-wrap{

  margin: 0px 1em;
  display: flex;
  flex-flow: column wrap;
  //width: 14rem;
  height: auto;


  .related-blog-img{
    height: 12rem;
    width: 20rem;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .related-blog-body{
    font-family: 'Sen', sans-serif;

    .related-blog-title{
      width: 100%;
      padding: 1rem 0;
      text-align: left;
    }

    .related-blog-read-more{
      cursor: pointer;
    }
  }

}









.blogger-img{
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 10em;
  height: 10em;
  position: relative;
  border-radius: 5em;
  top: 1em;
  margin: auto;
}
.social-icons
{
  text-align:center;
  margin: 5em 0;
}

.social-icons
{
  padding-left:0;
  margin-bottom:0;
  list-style:none
}
.social-icons li
{
  display:inline-block;
  margin-bottom:4px
}
.social-icons li.title
{
  margin-right:15px;
  text-transform:uppercase;
  color:#96a2b2;
  font-weight:700;
  font-size:13px
}
.social-icons a{
  background-color:#eceeef;
  color:#818a91;
  font-size:16px;
  display:inline-block;
  //line-height:44px;
  //width:44px;
  //height:44px;
  line-height: 32px;
  width: 2rem;
  height: 2rem;
  text-align:center;
  margin-right:8px;
  border-radius:50%;
  -webkit-transition:all .2s linear;
  -o-transition:all .2s linear;
  transition:all .2s linear
}
.social-icons a:active,.social-icons a:focus,.social-icons a:hover
{
  color:#fff;
  background-color:#29aafe
}
.social-icons.size-sm a
{
  line-height:34px;
  height:34px;
  width:34px;
  font-size:14px
}
.social-icons a.facebook:hover
{
  background-color:#3b5998
}
.social-icons a.pinterest:hover
{
  background-color: #E60023
}
.social-icons a.whatsapp:hover
{
  background-color: #35bb2f
}
.social-icons a.instagram:hover
{
  background-color: #ec4f8a
}
.social-icons a.google-plus:hover
{
  background-color: #db4a39
}
.social-icons a.twitter:hover
{
  background-color: #00acee
}
.social-icons a.linkedin:hover
{
  background-color: #0e76a8
}
@media (max-width:767px)
{
  .social-icons li.title
  {
    display:block;
    margin-right:0;
    font-weight:600
  }
}
