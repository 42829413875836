
.nav-links-drawer-wrapper {
  height: 100%;
  position: fixed;
  z-index: 2;
  top: 0;
  right: 0;
  background-color: #f2f2f2;
  overflow-x: hidden;
  transition: 0.5s;


  .nav-links-drawer-head-close-btn {
    text-align: right;
    margin: 1em;
  }

  .nav-links-drawer-ul-wrap {


    .nav-links-drawer-ul {
      display: flex;
      flex-flow: column wrap;
      -ms-flex-wrap: wrap;
      padding-left: 0;
      margin-bottom: 0;
      list-style: none;

      .nav-links-drawer-btn-wrap {
        margin: 0.2em auto;
        text-align: left;
        width: 100%;
        padding: 0 2em;


        .nav-links-drawer-btn {
          font-weight: bold;
          color: #0e0e0e !important;
          height: 1em;
          margin: 0px 1em 0px 0px;
          font-family: Sen, sans-serif;
          font-size: 0.8em;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          text-decoration: none;
        }
      }

    }
  }

  .client-support-links {


    .client-support-links-drawer-ul {
      display: flex;
      flex-flow: column wrap;
      -ms-flex-wrap: wrap;
      padding-left: 0;
      margin-bottom: 0;
      list-style: none;

      .client-support-links-drawer-btn-wrap {
        margin: 0.2em auto;
        text-align: left;
        width: 100%;
        padding: 0 2em;

        .client-support-links-drawer-btn {
          height: 14px;
          margin: 0px 16px 0px 0px;
          font-family: Sen, sans-serif;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: right;
          text-decoration: none;
          color: rgb(3, 158, 255) !important;
        }

      }

    }
  }


}

.drawer-opened {
  width: 80%;
  @media screen and (max-width: 425px) {
    width: 100%;
  }
}

.drawer-closed {
  width: 0;
}




