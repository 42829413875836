.product-image-small-small {
    /*width: 8em;*/
    /*height: 5em;*/
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    /*margin: 1em 0;*/
    cursor: pointer;
}


@media (min-width: 322px) {
    .product-image-small-small-border-color {
        border-left: 2px solid #039eff;
    }

    .product-image-small-small-border-white {
        border-left: 2px solid #ffffff;
    }

    .product-image-small-small {
        width: 8em;
        height: 5em;
        margin: 1em 0;
    }

}

@media (max-width: 320px) {
    .product-image-small-small-border-color {
        border-bottom: 2px solid #039eff;
    }

    .product-image-small-small-border-white {
        border-bottom: 2px solid #ffffff;
    }

    .product-image-small-small {
        width: 5em;
        height: 5em;
        margin: 0 0.8em;
    }
}