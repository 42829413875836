.baixe-app-wrapper {
  width: 100%;
  /* background-color: lightgrey; */
  height: auto;
  display: flex;
  flex-flow: column wrap;
  text-align: center;
  padding: 3rem;
  @media screen and (max-width: 425px) {
    padding: 2rem 0.5rem;
  }

  .baixe-app-title {
    font-size: 2rem;
    font-weight: bold;
    font-family: 'Sen', sans-serif;

    @media screen and (max-width: 425px) {
      font-size: 1.5rem;
    }
  }

  .baixe-app-image-body {
    display: flex;
    flex-flow: column wrap;

    .image-wrapper {
      display: flex;
      flex-flow: row;
      justify-content: center;
      margin: 2rem 0;

      .img {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        height: 22rem;
        width: 100%;


        @media screen and (max-width: 425px) {
          height: 12rem;
        }
      }

    }

    .store-btns-wrapper {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;

      .store-btns {
        height: 3em;
        width: 9em;
        background-size: contain;
        background-repeat: no-repeat;
        margin: 0px 1.5em 0.2em 0px;
        cursor: pointer;

        @media screen and (max-width: 375px) {
          height: 2rem;
          width: 6rem;
        }

      }


    }

  }
}