.empty-bag-wrap {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  width: 100%;
  height: 18rem;
  text-align: center;
  margin: 2rem auto;

  .icon {
    font-size: 4rem;
    color: lightgrey;
  }

  .label {
    font-size: 2rem;
    font-family: Sen, san-serif;
    font-weight: bold;
  }

  .btn {
    width: 12rem;
    background-color: #5db7fe;
    color: white;
    font-weight: bold;
    margin: 2rem auto;
  }
}
 .cart-continue-btn {
   cursor: pointer;
    width: 17%;
    padding: 10px;
    background-color: #5db7fe;
    border-radius: 4px;
    color: white;
    font-weight: bold;
    margin: 2rem auto;
  }

.checkout-process-wrap {
  margin: 0 0 2em 0;
  width: 100%;
  background-color: #f2f2f2;

  .section-wrap {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: auto;
    max-width: 22rem;

    @media screen and (max-width: 425px) {
      max-width: 18rem;
    }

    .back-btn {
      color: white;
      font-weight: bold;
      font-size: 1rem;
      font-family: Sen, san-serif;
      margin: 2rem auto 2rem 0;
      width: 100%;
    }
  }

  .checkout-process-items {
    margin: 1.5em 0;
    background-color: white;
    border-radius: 6px;
    width: 100%;
    height: auto;

    .checkout-bag-item-img {
      width: 6em;
      height: 6em;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin: auto;
    }

    .checkout-process-items-head{
      border-bottom: 2px solid #ececec;
      padding: 1.3em 0;


      .checkout-process-head-icon{
        width: 2em;
        height: 1.8em;
        margin: 0 1em;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }

      .checkout-process-head-items{
        //margin: 0 0.5em;
        font-family: Sen, sans-serif;
        font-size: 22px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #1a1a1a;

        .checkout-process-head-items-count{
          margin: 0 0.3em 0 0;
        }
      }

      .checkout-process-head-sub-title {
        font-family: Sen, sans-serif;
        font-size: 22px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #666666;
        margin: 0 0.5em;
      }
    }


  }
}