.product-detail-wrapper{
    width: 100%;
    height: auto;
    padding: 1em 0 0;
    border-radius: 4px;
    background-color: #f2f2f2;
}

.product-detail-section{
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: row wrap;
}

.product-detail-main{
    /*flex: 2;*/
    /*margin: 0 1em 0 0;*/
}
.product-detail-overview{
    width: 100%;
    height: auto;
    padding: 24px 24px 112px 0;
    border-radius: 8px;
    background-color: #ffffff;
    display: flex;
    flex-wrap: wrap;
}

.related-products-links{
    /* flex: 1; */
    width: 100%;
    height: auto;
    /* padding: 24px 0 25px; */
    border-radius: 8px;
    background-color: #ffffff;
    /* margin: 0 2em; */
    margin: 0 0 2rem 0;
}

.breadcrumb{
    background-color: #f2f2f2 !important;
}

.breadcrumb .breadcrumb-item{
    margin: 0 0.5em;
    font-family: Sen;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999999;
}

.breadcrumb-item a{
    color: inherit;
}
.breadcrumb-item.active{
    margin: 0 0.5em;
    font-family: Sen;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333333;
}

.product-image-small{
    width: 8em;
    height: 5em;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 1em 0;
    border-left: 2px solid #039eff;
}

.product-selected-image-wrap{
    padding: 1em 0 0 0;
    margin: auto;
}

.product-image-large{
    width: 24em;
    height: 36em;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.product-screens{
    display: flex;
    flex-wrap: wrap;
}

.product-info{
    font-family: Sen, sans-serif;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333333;
    width: 44%;
    padding: 0 0 0 2em;
    margin: auto;
}

.product-info-header{
    display: flex;
    flex-wrap: wrap;
}
.product-info-header-badge{
    width: 4em;
    height: 4em;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.product-info-header-seller{
    margin: 1em 0.2em;
}
.product-info-header-sold-by{
    font-family: Sen, sans-serif;
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333333;
}
.product-info-header-sold-by-seller{
    font-family: Sen, sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333333;

}

.product-info-header-like{
    width: 36%;
    text-align: right;
    margin: auto;
    padding: 1em;
}
.product-info-body{
    width: 90%;
}

.product-info-name{
    font-family: Sen;
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #666666;
    /* margin: 0.5em 0; */
    height: auto;
    width: 100%;
}
.product-info-pricing{
    height: auto;
    width: 100%;
    margin: 0.5em 0;
}
.product-info-price{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.product-info-price-label{
    font-family: Sen;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333333;
}
.product-info-price-original-price{
    font-family: Sen;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999999;
    padding: 0.3em;
    margin: 0 0.5em;
}

.product-info-price-discount{
    font-family: Sen;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #666666;
    padding: 0.3em;
    margin: 0 0.2em;
}
.product-info-tax-label{
    font-family: Sen;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #666666;
}

.product-info-rating{
    display: flex;
    flex-flow: row wrap;
}

.product-info-rating-badge{
    width: 78px;
    height: 32px;
    /* margin: 16px 16px 54px 90px; */
    padding: 8px 16px 7px;
    border-radius: 4px;
    background-color: #ffc72d;
    font-family: Sen;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333333;
    margin: 0 1em 0 0;
}

.product-info-rating-figures{
    font-family: Sen;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #666666;
    padding: 0.8em;
}

.product-info-highlights{
    margin: 4em 0 0 0;
}

.product-info-highlights-title{
    font-family: Sen;
    font-size: 1em !important;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333333;
}

.product-info-highlight-list{
    font-family: Sen;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #666666;
    margin: 1em 0;
    padding: 0 0 0 1em;
}
.product-info-highlight-item{
    margin: 0.5em 0;
}
.product-buy-btn-wrap{
  margin: 2rem 0;
}

.product-buy-btn{
    width: 18em;
    height: auto;
    /* margin: auto; */
    padding: 1em;
    text-align: center;
    border-radius: 8px;
    background-color: #039eff;
    font-family: Sen;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.37px;
    text-align: center;
    color: #ffffff;
}
.product-buy-btn:hover{
    color: #ffffff;
}
.related-products-link-label{
    width: 100%;
    font-family: Sen, sans-serif;
    font-size: 0.8em;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333333;
    padding: 0.5em 0 0 0;
}
.product-detail-specs{
    margin: 1.5em 0;
}
.product-detail-specs-tabs{
    background-color: white;
    border-bottom: 1px solid lightgray;
    height: auto;
    padding: 1em 0 0 0;
    border-radius: 0.5em 0.5em 0 0;
}

.product-detail-specs-tab-link{
    color: inherit;
    font-family: Sen, sans-serif;
}
.product-detail-specs-tab-active{
    font-weight: bold;
    border-bottom: 0.2em solid #039eff;
}

.product-detail-specs-tab-header-wrap{
    background-color: white;
}
.product-detail-specs-tab-header{
    display: flex;
    padding: 1em;
}
.product-detail-specs-tab-header-badge{
    width: 4em;
    height: 4em;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.product-detail-specs-tab-header-seller{
    width: 100%;
    padding: 1em;
}
.product-detail-specs-tab-header-sold-by{
    font-family: Sen, sans-serif;
    font-size: 0.7em;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333333;
}
.product-detail-specs-tab-header-sold-by-seller{
    font-family: Sen, sans-serif;
    font-size: 1em;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333333;
}
.product-detail-specs-tab-header-des{
    font-family: Sen, sans-serif;
    font-size: 0.9em;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333333;
    margin: 1em 0;
}
