.product-detail-wrapper{
    width: 100%;
    height: auto;
    /*padding: 1em 0 0;*/
    padding: 1em;
    border-radius: 4px;
    background-color: #f2f2f2;
}

.breadcrumb{
    background-color: #f2f2f2 !important;
}

.breadcrumb .breadcrumb-item{
    margin: 0 0.5em;
    font-family: Sen;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999999;
}

.breadcrumb-item a{
 color: inherit;
}
.breadcrumb-item.active{
    margin: 0 0.5em;
    font-family: Sen;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333333;
}

.product-image-small{
    width: 8em;
    height: 5em;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 1em 0;
    border-left: 2px solid #039eff;
}

.product-selected-image-wrap{
    padding: 1em 0 0 0;
    margin: auto;
}

.product-image-large{
    width: 24em;
    height: 36em;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.product-screens{
    display: flex;
    flex-wrap: wrap;
}
























