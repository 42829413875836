.result-right-head-tags-wrap{
  display: flex;
  flex-flow: row wrap;
  margin: 0 0 0.4em 0;

  .result-right-head-tags{
    display: flex;
    flex-flow: row wrap;
  }
  .result-right-head-tags-clear-all{
    font-family: Sen;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #039eff;
    padding: 1em;
    cursor: pointer;
  }
  .head-tag-icon{
    cursor: pointer;
  }
}