

.faqs-wrap{
  //margin: 4rem 0;
  .faqs-header-wrap{

  }
  .faqs-body-wrap{
    margin: 2rem;
    .faqs-left-toggles{
      font-family: Sen, sans-serif;
      .faqs-left-ul{
        list-style-type: none;
        padding: 0;
        max-height: 16rem;
        overflow-y: auto;
        font-size: 1.2rem;


        .faqs-left-li{
          padding: 0.5rem 0 0.5rem 4rem;
          border-left: 3px solid lightgray;
          cursor: pointer;
        }
        .faqs-left-li.active-tab{
          padding: 0.5rem 0 0.5rem 4rem;
          border-left: 3px solid #5db7fe;
          color: #5db7fe;
          cursor: pointer;
          font-weight: bold;
        }
      }


    }
    .faqs-right-toggles{
      .faqs-right-title{
        font-size: 1.5rem;
        text-align: left;
        font-weight: bold;
        padding: 0 0 0 0.5rem;
      }
      .faqs-right-detail-toggles{

      }
    }
  }

}