
.checkout-process-bag-item{
  border-bottom: 1px solid #ececec;
  margin: 2em 1em 0em;
  padding: 0 0 1.2em 0;

  .checkout-bag-item-img-wrap {
    margin: 0 auto 1em;

    .checkout-bag-item-img {

      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin: auto;
      @media screen and (min-width: 768px) {
        width: 6em;
        height: 6em;
      }
      @media screen and (max-width: 425px) {
        width: 12em;
        height: 12em;
      }
    }
  }

  .checkout-bag-item-detail {
    width: 100%;
    height: auto;

    .bag-item-name {
      font-family: Sen;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #1a1a1a;
    }
    .bag-item-sold-by{
      margin: 0.2em 0;
      font-family: Sen, sans-serif;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #666666;
      font-size: 12px;

      .bag-item-sold-by-title{
        font-weight: bold;
      }
      .bag-item-sold-by-brand{
        font-weight: normal;
        margin: 0 0.5em;
      }

    }

    .bag-item-price{
      font-family: Sen, sans-serif;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: right;
      color: #1a1a1a;
    }
    .bag-item-price-discount{
      font-family: Sen;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #666666;
      text-align: right;
      margin: 0.2em 0;
      text-decoration: line-through;
    }
  }



  .checkout-bag-item-actions{
    width: 100%;
    height: auto;
    font-family: Sen;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #039eff;

    @media screen and (min-width: 768px) {
      margin: 3em 0 0 0;

    }
    @media screen and (max-width: 425px) {
      margin: 1em 0 0 0;

    }

    .bag-item-add-offer {
      //margin: 0 0.5em;
      width: fit-content;
      cursor: pointer;
    }

    .bag-item-more-actions {
      float: right;

      .bag-item-remove {
        margin: 0 1em 0 0;
        cursor: pointer;

      }

      .bag-item-save-for-later {
        margin: 0 0 0 1em;
        cursor: pointer;

      }
    }
  }

}

.checkout-bag-sm {
  @media screen and (min-width: 768px) {
    display: none;
  }
}


.checkout-bag-lg {
  @media screen and (max-width: 425px) {
    display: none;
  }
}


.bag-item-price-wrap {
  width: 100%;
  margin: 1em 0;
}

.bag-item-add-offer-wrap {
  width: 35%;
}

.bag-item-more-actions-wrap {
  width: 65%;
}


