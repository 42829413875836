.product-screens-small {
    display: flex;
    flex-wrap: wrap;
}


@media (max-width: 1024px) and (min-width: 769px) {
    .product-image-large-small {
        width: 14em;
        height: 28em;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }

    .product-selected-image-wrap-small {
        padding: 1em 0 0 0;
    }

    .product-images-list-wrap-small-horizontal {
        display: none;
    }
}

@media (min-width: 1025px) {
    .product-image-large-small {
        width: 24em;
        height: 36em;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }

    .product-selected-image-wrap-small {
        padding: 1em 0 0 0;
        margin: auto;
    }

    .product-images-list-wrap-small-horizontal {
        display: none;
    }
}

@media (max-width: 768px) and (min-width: 426px) {
    .product-image-large-small {
        width: 22em;
        height: 26em;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }

    .product-selected-image-wrap-small {
        padding: 1em 0 0 0;
        margin: auto;
    }

    .product-images-list-wrap-small-horizontal {
        display: none;
    }
}

@media (max-width: 425px) {
    .product-image-large-small {
        width: 12em;
        height: 26em;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }

    .product-selected-image-wrap-small {
        padding: 1em 0 0 0;
        margin: auto;
    }

    .product-images-list-wrap-small-horizontal {
        display: none;
    }
}

@media (max-width: 320px) {
    .product-images-list-wrap-small-horizontal {
        display: -webkit-box;
        display: -moz-box;
        max-width: 18em;
        overflow-x: auto;
    }

    .product-images-list-wrap-small {
        display: none;
    }
}