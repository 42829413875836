.missing-things-toggles-wrapper {
  justify-content: space-around;
  display: flex;
  flex-flow: column wrap;
  margin: 4rem auto;

  @media screen and (max-width: 425px) {
    margin: auto;
  }

  .title {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    font-size: 3rem;
    font-weight: bold;
    //margin: 2rem auto;
    padding: 2rem;
    text-align: center;
    font-family: 'Sen', sans-serif;

    @media screen and (max-width: 425px) {
      font-size: 1.5rem;
    }
  }

  .toggle-tabs {
    max-width: 50%;
    margin: auto;

    @media screen and (max-width: 768px) {
      max-width: 85%;
    }

  }
}