
#sobrebayqi {
  margin: 4rem auto;
  display: flex;
  flex-flow: row wrap;

  @media screen and (max-width: 425px) {
    margin: auto;
  }

  .why-payment-left {

    .why-payment-left-img {
      background-position: center;
      background-size: contain;
      height: 22rem;
      width: 100%;
      background-repeat: no-repeat;
      float: right;

      @media screen and (min-width: 1025px) {
        height: 22rem;
        width: 32rem;
      }

      @media screen and (max-width: 1024px) and (min-width: 769px) {
        height: 29rem;
        width: 100%;
        background-color: #97dff7;
      }


      @media screen and (max-width: 425px) {
        height: 18rem;
      }
      @media screen and (max-width: 375px) {
        height: 16rem;
      }
      @media screen and (max-width: 320px) {
        height: 12rem;
      }


    }
  }

  .why-payment-right {
    font-family: Sen, sans-serif;

    @media screen and (max-width: 768px) and (min-width: 426px) {
      width: 75%;
      margin: 2rem auto 0;
    }


    .head-title {
      font-size: 2rem;
      font-weight: bold;
      max-width: 100%;

      @media screen and (max-width: 768px) and (min-width: 426px) {
        font-size: 5vw;
      }
      @media screen and (max-width: 425px) {
        font-size: 6vw;
      }


    }

    .why-payment-ul {
      list-style: none;
      padding: 0;
      margin: 2rem auto;

      .why-payment-li {
        display: flex;
        flex-flow: column wrap;
        margin: 1rem auto;

        .li-title-wrap {
          display: flex;
          flex-flow: row wrap;

          i {
            font-size: 1.4rem;
            margin: 0.2rem 1.3rem 0 0;
            color: #6bd5fd;
          }
        }

        .title {
          font-weight: bold;
          font-size: 1.2rem;

          @media screen and (max-width: 425px) {
            font-size: 5vw;
          }


        }
      }
      .li-paragraph{
        width: 75%;
        margin: 0 0 0 2.5rem;
      }

    }
  }
}
