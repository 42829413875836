.all-in-cat-card-wrap {
  display: flex;
  flex-flow: column wrap;
  width: 113px;
  height: 189px;
  margin: 0px 8px;
  padding: 8px 0px 0px;
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  cursor: pointer;
}

.all-in-cat-card-img-wrap {
  flex: 1 1 0%;
  width: 95px;
  height: 110px;
  margin: 0px 8px 17px;
  padding: 14px 22px 0px;
  border-radius: 8px;
  border: 1px solid lightgray;
  cursor: pointer;
}

.all-in-cat-card-img-title {
  flex: 1 1 0%;
  width: 100%;
  height: 17px;
  font-family: Sen, sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: rgb(51, 51, 51);
}

.all-cat-card-wrap{
  display: flex;
  flex-flow: column wrap;
  /*width: 113px;*/
  /*height: 189px;*/

  width: 7rem;
  height: 11rem;
  margin: 0px auto;
  padding: 8px 0px 0px;
  background-color: rgb(255, 255, 255);
  cursor: pointer;
  border-bottom: 4px solid white;
}

.all-cat-card-img-wrap{
  flex: 1 1 0%;
  width: 95px;
  height: 110px;
  margin: 0px 8px 17px;
  padding: 14px 8px 0px;
  border-radius: 8px;
  background-color: rgb(240, 240, 240);
}

.all-cat-card-img-title{
  flex: 1 1 0%;
  width: 100%;
  height: 17px;
  font-family: Sen, sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: rgb(51, 51, 51);
}