.products-loader-wrap {
  height: 16rem;
  width: 95%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 2rem;

  .product-loader {
    height: inherit;
    width: 20%;
  }
}