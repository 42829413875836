.white-logo {
  width: 13rem !important;
  height: 9rem !important;
  @media screen and (max-width: 425px) {
    margin: auto !important;
  }

}


.site-footer {
  background-color: #0c9cfb;
  padding: 0.5rem 0 0 0;
  font-size: 15px;
  line-height: 24px;
  color: #ffffff;
  text-align: left;
  font-family: Sen, sans-serif;

  .logo-row {
    display: flex;
    flex-flow: row wrap;
    justify-content: start;

    @media screen and (max-width: 425px) {
      flex-flow: column wrap;
    }
  }


  .links-sec-wrap {
    display: flex;
    flex-flow: row wrap;

    .links-sec {
      flex: 1;
      @media screen and (max-width: 768px) {
        margin: 1rem;
      }
    }
  }

}

.site-footer hr {
  border-top-color: #bbb;
  opacity: 0.5
}

.site-footer hr.small {
  margin: 20px 0
}

.site-footer h6 {
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  margin-top: 5px;
  letter-spacing: 2px
}

.site-footer a {
  color: #737373;
}

.site-footer a:hover {
  color: #33c7cc;
  text-decoration: none;
}

.footer-links {
  padding-left: 0;
  list-style: none;
  margin: 1rem auto;
}

.footer-links li {
  display: block;
  margin: 0.5rem auto;
}

.footer-links a {
  color: white;
  cursor: pointer;
}

.footer-links a:active, .footer-links a:focus, .footer-links a:hover {
  color: black;

  //text-decoration:none;
}

.footer-links.inline li {
  display: inline-block
}

.site-footer .social-icons {
  text-align: right
}

.site-footer .social-icons a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin-left: 6px;
  margin-right: 0;
  border-radius: 0.5em;
  background-color: whitesmoke;
}

.copyright-text {
  margin: 0
}

@media (max-width: 991px) {
  .site-footer [class^=col-] {
    margin-bottom: 30px
  }
}

@media (max-width: 767px) {
  .site-footer {
    padding-bottom: 0
  }
  .site-footer .copyright-text, .site-footer .social-icons {
    text-align: center
  }
}

.social-icons.social-icons-footer {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  padding: 3rem 0;

  @media screen and (max-width: 425px) {
    padding: 0 1rem 2rem;
  }

}

.social-icons li {
  display: inline-block;
  margin-bottom: 4px
}

.social-icons li.title {
  margin-right: 15px;
  text-transform: uppercase;
  color: #96a2b2;
  font-weight: 700;
  font-size: 13px
}

.social-icons a {
  background-color: #eceeef;
  color: #818a91;
  font-size: 16px;
  display: inline-block;
  line-height: 44px;
  width: 44px;
  height: 44px;
  text-align: center;
  margin-right: 8px;
  border-radius: 50%;
  -webkit-transition: all .2s linear;
  -o-transition: all .2s linear;
  transition: all .2s linear
}

.social-icons a:active, .social-icons a:focus, .social-icons a:hover {
  color: #fff;
  background-color: #29aafe
}

.social-icons.size-sm a {
  line-height: 34px;
  height: 34px;
  width: 34px;
  font-size: 14px
}

.social-icons a.facebook:hover {
  background-color: #3b5998
}

.social-icons a.youtube:hover {
  background-color: #d91b1b
}

.social-icons a.whatsapp:hover {
  background-color: #35bb2f
}

.social-icons a.instagram:hover {
  background-color: #ec4f8a
}

@media (max-width: 767px) {
  .social-icons li.title {
    display: block;
    margin-right: 0;
    font-weight: 600
  }
}
