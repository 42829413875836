.left-sec-wrap{
  display: flex;
  flex-flow: column wrap;
  width: 75%;
  height: auto;
  margin: 5rem auto;
  font-family: Sen, sans-serif;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.35px;


  @media screen and (max-width: 425px) {
    margin: auto;
  }

  .title-one {
    font-size: 1rem;
    font-weight: bold;
  }

  .title-two {
    font-size: 2rem;
    font-weight: bold;
    margin: 0 0 2rem 0;

    @media screen and (max-width: 425px) {
      font-size: 1.2rem;
    }


  }
  .title-three{
    font-size: 1.2rem;
    margin: 0 0 2rem 0;
  }
  .btn-wrapper{

  }

}

.sec-two-img {
  width: 100%;
  height: 24rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  @media screen and (max-width: 425px) {
    height: 14rem;
  }
}