.sign-up-title{
    font-size: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: bold;
}
.red{
  color: red;
}
  .eye {
    font-size: 20px;
    margin-right: 5px;
    color: gray !important;
  }
  .eye-btn {
    background-color: transparent;
    border: 0px solid gray;
  }
.signup {
    background-color: #5db7fe;
    width: 100px;
    border: 1px solid #5db7fe;
    border-radius: 5px;
    padding: 12px;
    margin-left: 35%;
    color: white;
    font-weight: bold;
  }
   .already-acc {
    color: gray;
    margin-left: 15%;
    margin-top: 3%;
  }
  .already-acc-signup {
    color: gray;
    margin-left: 21%;
    margin-top: 3%;
  }
  .already-login:hover {
    color: #007bff;
    cursor: pointer;
  }


  .Login {
    background-color: #5db7fe;
    width: 100px;
    border: 1px solid #5db7fe;
    border-radius: 5px;
    padding: 12px;
    margin-left: 35%;
    margin-top: -10px;
    color: white;
    font-weight: bold;
  }
  .ok-btn {
    background-color: rgb(148, 4, 4);
    width: 30%;
    margin-left: 80%;
    margin-top: 20px;
    border: 1px solid rgb(148, 4, 4);
    border-radius: 5px;
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .ok-btn-status {
    background-color: #5db7fe;
    width: 30%;
    margin-top: 20px;
    border: 1px solid #5db7fe;
    border-radius: 5px;
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .error-icon {
    color: rgb(148, 4, 4);
    font-size: 50px;
    margin-right: 10px;
  }
  .error-msg {
    color: red;
    font-size: 12px;
  }
  .error-in-head-title {
    font-family: Sen, sans-serif;
    font-size: 50px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgb(148, 4, 4);
  }
  .verify-btn {
    background-color: #49abf7;
    width: 120px;
    border: 1px solid #49abf7;
    border-radius: 5px;
    padding: 6px;
    padding-left: 14px;
    padding-right: 14px;
    margin-left: 32%;
    margin-top: 15px;
    color: white;
    font-weight: bold;
  }
  .forgot-btn {
    background-color: #49abf7;
    width: 120px;
    border: 1px solid #49abf7;
    border-radius: 5px;
    padding: 6px;
    padding-left: 14px;
    padding-right: 14px;
    margin-left: 32%;
    color: white;
    font-weight: bold;
  }
  .signup {
    background-color: #49abf7;
    width: 120px;
    border: 1px solid #49abf7;
    border-radius: 5px;
    padding: 6px;
    padding-left: 14px;
    padding-right: 14px;
    margin-left: 33%;
    margin-top: 10px;
    color: white;
    font-weight: bold;
  }

  .inp {
    border: 1px solid #ced4da;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .inp-grp {
    border: 0px solid gray;
    background-color: transparent;
  }
  .inp-grp:focus {
    outline: none;
    box-shadow: none;
  }
  .already-acc {
    color: gray;
    margin-left: 15%;
    margin-top: 3%;
  }
  .already-acc-signup {
    color: gray;
    margin-left: 21%;
    margin-top: 3%;
  }
  .already-login:hover {
    color: #007bff;
    cursor: pointer;
  }
  .verify,
  .verify:hover {
    color: #007bff;
    cursor: pointer;
    font-size: 12px;
    margin-left: 71%;
    text-decoration: none;
  }
  .sign-in-confirm-btn-wrap {
    margin: 1em 0;
    cursor: pointer;

    .sign-in-confirm-btn {
      padding: 1em;
      border-radius: 6px;
      background-color: #039eff;
      font-family: Sen, sans-serif;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
    }
  

  .custom-modal-dialog {
    width: 100%;
    max-width: 30rem;
    //max-width: 28em;
    //margin: 1em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .custom-modal-close-btn {
      position: absolute;
      width: 100%;

      .custom-modal-close-btn-icon {
        font-size: 1.5em;
        color: inherit;
        cursor: pointer;
        float: right;
        margin: 1rem;
        color: #999999cf;
      }
    }
    .sign-in-head-title {
      font-family: Sen, sans-serif;
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #1a1a1a;
      padding: 0.5em 0;
      text-align: center;
      margin: 0 auto 0.5em;
    }
    .sign-in-subtitle {
      font-family: Sen, sans-serif;
      font-size: 16px;
    }

    .sign-in-modal-body {
      padding: 2em 4em;

      .sign-in-modal-body-left {
        //width: 65%;
        width: 100%;
        //@media screen and (max-width: 425px) {
        //  width: 100%;
        //}

        .sign-in-wrap {
          //margin: 1.5em 1em;
          background-color: white;
          border-radius: 6px;

          .sign-in-head {
            //border-bottom: 2px solid #ececec;
            //padding: 0 1em 0.7em;
            padding: 0 0 2em 0;
            height: auto;
            width: 100%;

            .sign-in-head-icon {
              width: 3em;
              height: 3em;
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              margin: 0.2em 0.5em 0em 0.5em;
            }

            .sign-in-head-sub-title {
              font-family: Sen, sans-serif;
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #666666;

              text-align: center;
            }
          }

          .sign-in-detail {
            //margin: 1.5em;

            .sign-in-input-label {
              font-family: Sen, sans-serif;
              font-size: 0.8em;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #333333;
            }

            .sign-in-input {
              font-family: Sen, sans-serif;
              font-size: 0.9em;
              //font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              width: 100%;
              height: 3em;
              margin: auto;
              border-radius: 6px;
              background-color: #f2f2f2;
            }

            .forgot-pass-btn-wrap {
              margin: 0.5em 0 0 0;
              text-align: right;
              cursor: pointer;

              .forgot-pass-btn {
                border-radius: 6px;
                background-color: #ffffff;
                font-family: Sen, sans-serif;
                font-size: 14px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: right;
                color: #039eff;
                cursor: pointer;
              }
            }
          }

          .create-new-account {
            font-family: Sen, sans-serif;
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #039eff;
            text-align: center;
            margin: auto;
            cursor: pointer;
          }
        }
      }

      .sign-in-modal-body-right {
        height: 100%;
        //margin: 0 3em 0 auto;
        margin: auto;
        //@media screen and (max-width: 425px) {
        //  margin: auto;
        //}
      }
    }

    .sign-up-modal-body {
      padding: 2em 4em;

      .sign-up-modal-body-left {
        //width: 65%;
        width: 100%;
        //@media screen and (max-width: 425px) {
        //  width: 100%;
        //}

        .sign-up-wrap {
          //margin: 1.5em 1em;
          background-color: white;
          border-radius: 6px;
          text-align: center;

          .select-acc-checkboxes {
            margin: 1em 0;
          }

          .sign-up-head {
            //border-bottom: 2px solid #ececec;
            //padding: 0.7em 1em;
            height: auto;
            width: 100%;

            .sign-up-head-title {
              font-family: Sen, sans-serif;
              font-size: 22px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #1a1a1a;
              padding: 0.5em 0;
            }

            .sign-up-head-sub-title {
              font-family: Sen, sans-serif;
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #666666;
            }
          }

          .sign-up-detail {
            margin: 1.5em;
            text-align: left;

            .sign-up-input-label {
              font-family: Sen, sans-serif;
              font-size: 0.8em;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #333333;
            }

            .sign-up-input {
              font-family: Sen, sans-serif;
              font-size: 0.9em;
              //font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              width: 100%;
              height: 3em;
              border-radius: 6px;
              background-color: #f2f2f2;
            }

            .sign-up-pin-input {
              font-family: Sen, sans-serif;
              font-size: 0.9em;
              //font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              max-width: 3em;
              height: 3em;
              margin: 0 0.4em 0 0;
              border-radius: 6px;
              background-color: #f2f2f2;
            }

            .sign-up-confirm-btn-wrap {
              margin: 1em 0;
              cursor: pointer;

              .sign-up-confirm-btn {
                padding: 1em;
                border-radius: 6px;
                background-color: #039eff;
                font-family: Sen, sans-serif;
                font-size: 14px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #ffffff;
              }
            }

            .forgot-pass-btn-wrap {
              margin: 1em 0;
              cursor: pointer;

              .forgot-pass-btn {
                border-radius: 6px;
                background-color: #ffffff;
                font-family: Sen, sans-serif;
                font-size: 14px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #039eff;
                cursor: pointer;
              }
            }
          }

          .create-new-account {
            font-family: Sen, sans-serif;
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #039eff;
            text-align: center;
            margin: auto;
            cursor: pointer;
          }
        }
      }

      .sign-up-modal-body-right {
        height: 100%;
        //margin: 0 3em 0 auto;
        margin: auto;
        //@media screen and (max-width: 425px) {
        //  margin: auto;
        //}

        .create-new-account {
          font-family: Sen, sans-serif;
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #039eff;
          text-align: center;
          margin: auto;
          cursor: pointer;
        }
      }
    }
  }
}

.custom-modal-open {
  display: block; /* Hidden by default */
}

.custom-modal-close {
  display: none; /* Hidden by default */
}
