.links-wrapper{
  display: flex;
  width: inherit;
  flex-wrap: wrap;
}
.footer-wrapper{
  display: flex;
  width: 100%;
  height: auto;
  padding: 4em;
  border-radius: 8px;
  background-color: rgb(93 183 254);
  flex-flow: row wrap;
}
.copyright-section{
  text-align: center;
  width: 100%;
  height: 5em;
  padding: 2em 1em;
  background-color: rgb(255, 255, 255);
}
.copyright-label{
  font-family: Sen, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: rgb(153, 153, 153);
}
.footer-images-left{
  display: flex;
  flex-flow: row wrap;
  margin: 4em 0 0 0;
  flex: 2;
}
.footer-images-right {
  display: flex;
  flex-flow: row wrap;
  margin: 4em 0 0 0;
}
.container {
  display: flex;
  flex-direction: row;
  height: auto !important;
}
.site-footer .logo-row{
  justify-content: space-between !important;
  width: -webkit-fill-available !important;
}