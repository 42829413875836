.sobre-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column wrap;
  margin: 3rem;

  @media screen and (max-width: 425px) {
    margin: 0 2rem;
  }

  .sobre-sec-wrapper:nth-child(2) {
    @media screen and (max-width: 425px) {
      flex-flow: column-reverse;
    }
  }

  .sobre-sec-wrapper {
    display: flex;
    flex-flow: row wrap;
    //margin: 0 1rem;
    //margin: 0 4rem;

    @media screen and (max-width: 425px) {
      flex-flow: column wrap;
      margin: 1rem auto;
    }


    .sobre-sec-left {
      flex: 1;
      background-color: #5db7fe;
      color: white;
      //padding: 3rem;
      //width: 50%;


      .text {
        font-family: 'Sen', sans-serif;
        @media screen and (min-width: 426px) {
          margin: 1rem 3rem;
        }

        @media screen and (max-width: 425px) {
          text-align: center;
          padding: 1rem;
        }

        .title {
          font-size: 3vw;
          font-weight: bold;
          text-align: center;
          @media screen and (max-width: 425px) {
            font-size: 6vw;
          }
        }

        .detail {
          font-size: 1.8vw;
          @media screen and (max-width: 425px) {
            font-size: 4vw;
          }
        }
      }

    }

    .sobre-sec-right {
      flex: 1;
      background-color: #5db7fe;
      //width: 50%;

      .img {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 100%;
        width: 100%;

        @media screen and (min-width: 1441px) {
          height: 47rem;
          background-size: cover;
        }

        @media screen and (max-width: 1440px) {
          height: 25rem;
          background-size: cover;
        }

        @media screen and (max-width: 1024px) {
          height: 19.5rem;
          background-size: cover;
        }

        @media screen and (max-width: 768px) {
          height: 15rem;
          background-size: cover;
        }

        @media screen and (max-width: 425px) {
          height: 14rem;
        }

        @media screen and (max-width: 375px) {
          height: 12rem;
        }
        @media screen and (max-width: 320px) {
          height: 10rem;
        }


      }
    }
  }

}