

a:hover {
  color: inherit;
}

.react-loading-skeleton {
  height: 100%;
}

/*::-webkit-scrollbar {*/
/*  width: 0.5em;*/
/*}*/

/*::-webkit-scrollbar-track {*/
/*  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);*/
/*}*/

/*::-webkit-scrollbar-thumb {*/
/*  background-color: darkgrey;*/
/*  outline: 1px solid slategrey;*/
/*}*/

/*::-webkit-scrollbar-thumb:hover {*/
/*  background: #555;*/
/*}*/

@media screen and (max-width: 600px) {
  .privacy-div {
    padding:0px 30px;
  }
}
@media screen and (min-width:601px) and (max-width: 900px) {
  .privacy-div {
    padding:0px 140px;
  }
}
@media screen and (min-width:901px) and (max-width: 1100px)  {
  .privacy-div {
    padding:0px 200px;
  }
}
@media screen and (min-width:1101px)  {
  .privacy-div {
    padding:0px 340px;
  }
}