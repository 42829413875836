
.social-icons-top{
  padding-left: 0 !important;
  margin: auto 0 !important;
  list-style: none !important;
}

.social-icons-top li.title {
  margin-right: 15px;
  text-transform: uppercase;
  color: #96a2b2;
  font-weight: 700;
  font-size: 13px
}

.social-icons-top a {
  background-color: #eceeef;
  color: #818a91;
  font-size: 16px;
  display: inline-block;
  //line-height:44px;
  //width:44px;
  //height:44px;
  line-height: 32px;
  width: 2rem;
  height: 2rem;
  text-align: center;
  margin-right: 8px;
  border-radius: 8px;
  -webkit-transition: all .2s linear;
  -o-transition: all .2s linear;
  transition: all .2s linear
}

.social-icons-top a:active, .social-icons-top a:focus, .social-icons-top a:hover {
  color: #fff;
  background-color: #29aafe
}

.social-icons-top.size-sm a {
  line-height: 34px;
  height: 34px;
  width: 34px;
  font-size: 14px
}

.social-icons-top a.facebook:hover {
  background-color: #3b5998
}

.social-icons-top a.youtube:hover {
  background-color: #d91b1b
}

.social-icons-top a.whatsapp:hover {
  background-color: #35bb2f
}

.social-icons-top a.instagram:hover {
  background-color: #ec4f8a
}

@media (max-width: 767px) {
  .social-icons-top li.title {
    display: block;
    margin-right: 0;
    font-weight: 600
  }
}
