.prod-qty-counter{
  border: 1px solid rgb(170, 170, 170);
  width: 30%;
  padding: 10px;
  height: 60px;
}
.counter-input {
  width: 25%;
  border: 1px solid rgb(212, 212, 212);
  background-color: rgb(190, 188, 188) ;
}
.input-group-addon {
  padding: 10px;
  width: 30%;
}
.product-detail-section{
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: row wrap;

  .product-detail-main{
    /*flex: 2;*/
    /*margin: 0 1em 0 0;*/

    .product-detail-overview{
      width: 100%;
      height: auto;
      padding: 1em 1em 1em 0;
      border-radius: 8px;
      background-color: #ffffff;
      display: flex;
      flex-wrap: wrap;

      .product-info {
        font-family: Sen, sans-serif;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #333333;
        padding: 0 0 0 2em;
        margin: auto;

        @media (max-width: 1024px) and (min-width: 769px) {
          width: 44%;

        }

        @media screen and (max-width: 768px) {
          width: 100%;
          margin: 3em 0 0 0;
        }

        .product-info-header {
          display: flex;
          flex-wrap: wrap;

          .product-info-header-badge {

            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            @media (max-width: 1024px) and (min-width: 769px) {
              width: 3em;
              height: 3em;
            }
            @media (min-width: 1025px) and (max-width: 768px) {
              width: 4em;
              height: 4em;
            }

          }

          .product-info-header-seller {

            @media (max-width: 1024px) and (min-width: 769px) {
              margin: 0.5em 0.2em;
            }

            @media (min-width: 1025px) and (max-width: 768px) {
              margin: 1em 0.2em;
            }

            @media (max-width: 320px) {
              width: 7em;
            }

            .product-info-header-sold-by {
              font-family: Sen, sans-serif;
              font-size: 10px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #333333;
            }
            .product-info-header-sold-by-seller{
              font-family: Sen, sans-serif;
              font-size: 14px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #333333;

            }


          }

          .product-info-header-like {

            text-align: right;
            margin: auto;
            padding: 1em;
            @media (max-width: 1024px) and (min-width: 769px) {
              width: 17%;
            }
            @media (max-width: 425px) and (min-width: 321px) {
              width: 15%;
            }

            @media (max-width: 320px) {
              width: 23%;
            }

          }

          .product-info-header-like i {
            @media (max-width: 1024px) and (min-width: 769px) {
              font-size: 1.5em;
            }
            @media (min-width: 1025px) and (max-width: 768px) {
              font-size: 1em;
            }
            @media (max-width: 425px) and (min-width: 321px) {
              font-size: 1.5em;
            }
            @media (max-width: 320px) {
              font-size: 1.5em;
            }
          }

        }

        .product-info-body{
          width: 90%;

          .product-info-name{
            font-family: Sen, sans-serif;
            font-size: 1.5em;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #666666;
            /* margin: 0.5em 0; */
            height: auto;
            width: 100%;

            @media (max-width: 320px) {
              font-size: 1.3em;
            }
          }

          .product-info-pricing{
            height: auto;
            width: 100%;
            margin: 0.5em 0;

            .product-info-price{
              display: flex;
              flex-wrap: wrap;
              width: 100%;


              .product-info-price-label {
                font-family: Sen, sans-serif;
                font-size: 1.4em;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #333333;
                @media (max-width: 320px) {
                  font-size: 1.2em;
                }
              }
              .product-info-price-original-price{
                font-family: Sen, sans-serif;
                font-size: 1em;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #999999;
                padding: 0.3em;
                margin: 0 0.5em;
                text-decoration: line-through;
                @media (max-width: 320px) {
                  font-size: 0.8em;
                }
              }

              .product-info-price-discount{
                font-family: Sen, sans-serif;
                font-size: 1em;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #666666;
                padding: 0.3em;
                margin: 0 0.2em;
                @media (max-width: 320px) {
                  font-size: 0.8em;
                }
              }
            }

            .product-info-tax-label{
              font-family: Sen,sans-serif;
              font-size: 0.6em;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #666666;
            }

          }

          .product-info-qty {
            display: flex;
            flex-flow: row wrap;
            margin: 2rem 0 0 0;

            .qty-label {
              font-weight: bold;
              font-family: 'Sen';
              line-height: 2em;
              margin: 0 2rem 0 0;
            }

            .qty-btns-wrapper {
              display: flex;
              flex-flow: row wrap;
              border: 1px solid lightgray;

              .minus-btn {
                width: 2rem;
                height: 2rem;
                background-color: lightgrey;
                text-align: center;
                //border-radius: 0.2rem;
                font-size: 1rem;
                cursor: pointer;

                i {
                  width: 100%;
                  line-height: 32px;
                  text-align: center;
                }
              }

              .qty {
                width: 3rem;
                height: 2rem;
                /* background-color: lightgrey; */
                text-align: center;
                border-radius: 0.2rem;
                font-size: 1rem;
                line-height: 32px;
                text-align: center;
              }

              .plus-btn {
                width: 2rem;
                height: 2rem;
                background-color: lightgrey;
                text-align: center;
                //border-radius: 0.2rem;
                font-size: 1rem;
                cursor: pointer;

                i {
                  width: 100%;
                  line-height: 32px;
                  text-align: center;
                }
              }

            }

          }

          .product-info-rating {
            display: flex;
            flex-flow: row wrap;


            .product-info-rating-badge {
              width: 78px;
              height: 32px;
              /* margin: 16px 16px 54px 90px; */
              padding: 8px 16px 7px;
              border-radius: 4px;
              background-color: #ffc72d;
              font-family: Sen, sans-serif;
              font-size: 0.9em;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #333333;
              margin: 0 1em 0 0;

              @media (max-width: 320px) {
                width: 6em;
                font-size: 0.7em;

              }
            }

            .product-info-rating-figures {
              font-family: Sen, sans-serif;
              font-size: 0.7em;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #666666;
              padding: 0.8em;
              @media (max-width: 320px) {
                font-size: 0.6em;
              }
            }
          }

        }

        .product-info-highlights{
          margin: 4em 0 0 0;


          .product-info-highlights-title{
            font-family: Sen;
            font-size: 1em !important;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #333333;
          }

          .product-info-highlight-list{
            font-family: Sen,sans-serif;
            font-size: 0.9em;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #666666;
            margin: 1em 0;
            padding: 0 0 0 1em;


            .product-info-highlight-item{
              margin: 0.5em 0;
            }
          }
        }

        .product-buy-btn-wrap {
          margin: 2rem 0;
          min-width: 2em;
          max-width: 18em;

          .product-buy-btn {
            width: 18em;
            height: auto;
            /* margin: auto; */
            padding: 1em;
            text-align: center;
            border-radius: 8px;
            background-color: #039eff;
            font-family: Sen,sans-serif;
            font-size: 1em;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.37px;
            text-align: center;
            color: #ffffff;
          }
          .product-buy-btn:hover{
            color: #ffffff;
          }

        }

      }
    }
  }

  .related-products-links{
    width: 100%;
    height: auto;
    border-radius: 8px;
    background-color: #ffffff;
    margin: 0 0 2rem 0;

    .related-products-link-label {
      width: 100%;
      font-family: Sen, sans-serif;
      font-size: 0.8em;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #333333;
      padding: 0.5em 0 0 0;
    }
  }


}
