.pp-wrap {
  margin: 2rem auto;

  .pp-title {
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
    margin: 2rem auto;
    font-family: 'Sen', sans-serif;
  }

  .pp-paragraph {
    font-size: 2rem;
    text-align: center;
    margin: 1rem auto 3rem;
  }

}