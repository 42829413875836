.checkout-payment-summary-wrap{
  margin: 1.5em 0;
  background-color: white;
  border-radius: 6px;

  .checkout-payment-summary-head{
    border-bottom: 2px solid #ececec;
    padding: 0.7em 1em;
    height: auto;
    width: 100%;

    .payment-summary-head-icon{
      width: 3em;
      height: 3em;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin: 0.2em 0.5em 0em 0.5em;
    }

    .payment-summary-head-title{
      font-family: Sen;
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #1a1a1a;
      padding: 0.5em 0;
    }

  }

  .checkout-payment-detail{
    padding: 1em 2em;
    border-bottom: 1px solid #ececec;
    width: 100%;
    height: auto;


    .payment-summary-sub-amount-detail{
      font-family: Sen,sans-serif;
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      margin: 0.5em 0;
      height: auto;
      width: 100%;

      .payment-summary-sub-amount-for{
        font-family: Sen,sans-serif;
        font-size: 16px;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        font-weight: bold;
        color: #1a1a1a;
      }
      .payment-summary-sub-amount{
        font-family: Sen,sans-serif;
        font-size: 16px;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        font-weight: normal;
        text-align: right;
        color: #666666;
      }

      .payment-summary-sub-amount-free-shipping{
        font-family: Sen,sans-serif;
        font-size: 16px;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        font-weight: bold;
        text-align: right;
        color: #2eab62;
      }

    }

  }

  .payment-summary-amount-payable{
    padding: 2em;
    font-family: Sen,sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-weight: bold;
    color: #1a1a1a;
    width: 100%;
    height: auto;

    .payment-summary-amount-payable-title{

    }
    .payment-summary-amount-payable-amount{
      text-align: right;
    }
  }
}
